@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* offer - offerCard */
.offerCard {
  width: 100%;
  max-width: 400px;
}

/* .about {
  background-image: url("../public/aboutBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
} */
.ReactModal__Content--after-open {
  margin: auto;
  padding: 0 !important;
  border: none !important;
}